import self from "../img/self.png";
import vc from "../img/vc-alt.png";
import ca from "../img/ca-alt.png";
import jlr from "../img/jlr-alt.png";
import portfolio from "../img/portfolio-alt.png";

/* Hi there! Thanks for checking out my portfolio template. Be sure to read the comments to get a better understanding of
how to make this template work best for you! */

export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];
/*
I highly recommend using a gradient generator like https://gradientgenerator.paytonpierce.dev/ to generate a pair of colors that you like.
These colors will be used to style your name on the homepage, the background of your picture, and some other accents throughout
the site.
 */

/*
So let's get started! Some of the info below is pretty self-explanatory, like 'firstName' and 'bio'. I'll try to explain anything
that might not be obvious right off the bat :) I recommend looking at the template example live using "npm start" to get an idea
of what each of the values mean.
 */

export const info = {
  firstName: "Aagam",
  lastName: "Jain",
  initials: "AJ", // the example uses first and last, but feel free to use three or more if you like.
  position: "a Full Stack Developer",
  selfPortrait: self, // don't change this unless you want to name your self-portrait in the "img" folder something else!
  gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
  baseColor: colors[0],
  miniBio: [
    // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
    {
      emoji: "☕",
      text: "Fueled by coffee",
    },
    {
      emoji: "🌎",
      text: "Based in India",
    },
    {
      emoji: "💼",
      text: 'System Engineer at <a href="https://www.visualcogs.com" target="_blank">Visual COGS</a>',
    },
    {
      emoji: "📧",
      text: '<a href="mailto:iamaagamjain@gmail.com">iamaagamjain@gmail.com</a>',
    },
    {
      emoji: "📜",
      text: '<a href="/AagamJain-FS.pdf" download="Aagamjain.pdf">Know me more</a>',
    },
  ],
  socials: [
    {
      link: "https://www.facebook.com/agamj474",
      icon: "fa fa-facebook",
    },
    {
      link: "https://www.instagram.com/agamj474/?hl=en",
      icon: "fa fa-instagram",
    },
    {
      link: "https://github.com/agamj474",
      icon: "fa fa-github",
    },
    {
      link: "www.linkedin.com/in/iamaagamjain",
      icon: "fa fa-linkedin",
    },
    {
      link: "https://stackoverflow.com/users/6213170/aagam-jain",
      icon: "fa fa-stack-overflow",
    },
    // Feel free to remove any of these that you don't have. I'll write an FAQ on how to add new ones later, let me know if you have a request for an icon!
    // Just change the links so that they lead to your social profiles.
  ],
  bio: `Hello! I'm Aagam. I'm a systems engineer for Daily Code Solutions. 
            I studied MCA(Master's in Computer Applications) at Govt. Engineering College Bikaner. 
            I enjoy listening old hindi songs, and I believe artificial intelligence will inevitably rule us all one day!`,
  skills: {
    proficientWith: [
      "Java (Spring Boot, Hibernate)",
      "Microservices",
      "Node (Express)",
      "Javascript",
      "React",
      "SQL (Oracle, MySQL)",
      "MongoDB",
    ],
    exposedTo: ["python", "docker", "Angularjs", "Angular 2+"],
  },
  hobbies: [
    {
      label: "reading",
      emoji: "📖",
    },
    {
      label: "music",
      emoji: "🎶",
    },
    {
      label: "movies",
      emoji: "🎥",
    },
    {
      label: "cooking",
      emoji: "🌶",
    },
    // Same as above, change the emojis to match / relate to your hobbies or interests.
    // You can also remove the emojis if you'd like, I just think they look cute :P
  ],
  portfolio: [
    // This is where your portfolio projects will be detailed
    {
      title: "Visual COGS",
      live: "https://visualcogs.com", //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
      image: vc,
    },
    {
      title: "Jaguar and Land Rover",
      live: "https://www.jaguarlandrover.com/",
      image: jlr,
    },
    {
      title: "Competency Analysis",
      live: "http://competency-analysis.surge.sh",
      image: ca,
    },
    {
      title: "Portfolio",
      live: "http://aagamjain.in/",
      image: portfolio,
    },
  ],
};
